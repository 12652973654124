.hid-origo {
  &__linq {
    &-white-card-min-height {
      min-height: 800px;
    }

    &-success-status-text {
      color: #94bb20;
    }
  }
  &__gateway-title {
    font-size: 24px;
    font-weight: 400;
  }
  &__gateway-label {
    font-size: 18px;
    font-weight: 400;
  }
  &__min-width-100 {
      min-width: 100%;
      flex-basis: 100%;
  }
}
.gateway-devices-pagination .hid-table__footer-result  {
  display: none;
}

.gateway-devices-pagination .hid-pagination__list:nth-last-child(2), .gateway-devices-pagination .hid-pagination__list:nth-last-child(1) {
  z-index: 1000 !important;
}

.gateway-devices-pagination .hid-pagination__list-item,
.gateway-devices-pagination .hid-pagination__list>button {
  min-width: 40px !important;
}

.gateway-devices-pagination {
  margin-top: 10px;
}
.hid-modal__body{
  padding-top: 0;
}
.gateway-card{
  padding-top: 0;
}