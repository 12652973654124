.hid-linq-changeOrg{
   flex: 1 1;
   background: linear-gradient(52.62deg, #00539B 20.39%, #006BC8 82.87%);

    &__header{
        flex: 1 1;
        width: 50%;
        background-color: white;
    }
    .header-action{
cursor: pointer;
    }
}

.hid-linq__logo {
    font-size: 18px;
    font-weight: 600;
    color: var(--color_endeavor)
}