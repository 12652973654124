.hid-origo {
  &__linq {
    &__onPrem-download-banner {
      background: linear-gradient(223deg, #006BC8 0%, #004C9F 100%);
      height: 154px;
      margin-bottom: 20px;
    }
    &__pt_pl-32 {
      padding-top: 32px;
      padding-left: 32px;
    }
    &__download-btn, &__download-btn:hover, &__download-btn:active {
      background-color: White;
      color: #002E56;
    }
    &__white-heading {
      color: white;
    }
    &__right-container {
      padding-top: 12px;
      display: flex;
      justify-content: right;
    }
    &__close-icon {
      color: white;
      cursor: pointer;
    }
  }
  &__hid-trademark{
    font-size: 12px;
  }
}