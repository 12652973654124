.hid-origo {
  &__login-main {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 470px;
  }
  &__login__intro {
    border-bottom-right-radius: 110px;
    background: linear-gradient(52.62deg, #00539b 20.39%, #006bc8 82.87%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-xxxxxl);
  }
  &__login__welcome {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__login__welcome-text {
    font-size: clamp(2rem, 4.5vw - 1rem, 4.25rem);
    margin-top: -98px; // logo + top padding
    &:after {
      font-size: 50%;
      content: "TM";
      left: 0px;
      position: relative;
      top: 10%;
      vertical-align: top;
    }
    
  }
   &__login__logo-text {
    font-size: clamp(1rem, 3vw - 1rem, 3rem);
    // margin-top: -98px; // logo + top padding
    // &:after {
    //   font-size: 40%;
    //   content: "TM";
    //   left: 0px;

    //   position: relative;
    //   top: 10%;
    //   vertical-align: top;
    // }
  }
  &__login__welcome-text-bottom {
    font-size: clamp(1rem, 3vw - 1rem, 3rem);
    font-weight: 400;
    margin-bottom: 35%;
    &:after {
      content: "TM";
      font-size: 50%;
      left: 0px;
      position: relative;
      top: 10%;
      vertical-align: top;
    }
  }
  &__login__tagline-text {
    font-size: clamp(1rem, 4.5vw - 1rem, 1.25rem);
    margin-top: var(--spacing-2);
    letter-spacing: 1px;
    
  }
  &__login__form {
    padding: var(--spacing-lg) 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__login-action-wrapper {
    display: grid;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 8px;
  }
}
