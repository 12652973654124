.hid-origo {
  &__barLayout {
    width: 259px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    position: relative;
    &__content {
      position: absolute;
      right: 12px;
      top: 55px;
      z-index: 10;
    }
  }
}
.logo-text {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 600;
}
.hid-header__logo {
  cursor: default;
}
