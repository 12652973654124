%hid-origo-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.hid-origo-circle {
  &--progress {
    @extend %hid-origo-dot;
    background-color: var(--color_progress);
  }
  &--vetting {
    @extend %hid-origo-dot;
    background-color: var(--color_vetting);
  }
  &--active {
    @extend %hid-origo-dot;
    background-color: var(--color_active);
  }
  &--draft {
    @extend %hid-origo-dot;
    background-color: var(--color_draft);
  }
  &--failed {
    @extend %hid-origo-dot;
    background-color: var(--color_failed);
  }
}
