.hid-linq {
  &__header {
    font-size: 36px;
    font-weight: 400;
  }
  &__default-color {
    color: var(--color-endeavor);
  }
  &__available-firmware {
    flex: 0.8;
  }
  &__reader-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
  }
  .reader-info {
    background: var(--color-white);
    box-shadow: var(--shadow-md);
    transition: all 0.5s ease-in-out;
    min-height: 4rem;
    border-radius: var(--radius-md);
    &__button {
      text-align: center;
    }
    &__count {
      flex-basis: 100%;
      text-align: right;
      padding: 5% 5% 1% 0;
    }
  }
  .hid-border-bottom {
    border-bottom: 1px solid var(--color-alto);
  }
  .line-height-24 {
    line-height: var(--spacing-06);
  }
  &__available-firmware {
    .hid-card.fixed-height{
      min-height: 65vh;
      max-height: 65vh;
      overflow: auto;
    }
  }
}
