:root {
  // spacing
  /* 8 */
  --spacing-base: 0.5rem;
  /* 8 * 2 */
  --spacing-2: 1rem;
  /* 8 * 3 */
  --spacing-lg: 1.5rem;
  /* 8 * 4 */
  --spacing-xl: 2rem;
  /* 8 * 5 */
  --spacing-xxl: 2.5rem;
  /* 8 * 6 */
  --spacing-xxxl: 3rem;
  /* 8 * 7 */
  --spacing-xxxxl: 3.5rem;
  /* 8 * 7 */
  --spacing-xxxxxl: 4rem;

  --letter-spacing: 0.5px;
  //colors
  --light_gray: #dddddd;
  --color_endeavor: #00539b;
  --color_progress: #b0cbea;
  --color_failed: #d31245;
  --color_delete: #ff4b55;
  --color_draft: #b6b6b6;
  --color_vetting: #f8971d;
  --color_active: #94bb20;
  --color_mobileid: #a0cffd;
  --color_reader: #007176;
  --color_dove_grey: #666666;
  --color_light_smoke: #f6f6f6;
  --color_tab_selected: #08387f;

  //font size
  --type-size-base: 1rem;
  --type-size-xs: 0.75rem;
  --type-size-sm: 0.875rem;
  --type-size-base: 1rem;
  --type-size-lg: 1.125rem;
  --type-size-xl: 1.25rem;
  --type-size-2xl: 1.625rem;
}
