@import "abstracts/_variables.scss";

.hid-galaxy {
  @import "abstracts/_mixins.scss";

  @import "../pages/login/login";

  @import "../widgets/CenteredLayout/centeredLayout.scss";

  @import "../widgets/Status/status";

  @import "../widgets/Tab/tab";

  @import "../widgets//ReadersTable/readersTable";

  @import "../pages/Linq/readers/gateWayPanel/gateWayPanel";

  @import "../pages/Linq/readers/editReader/editReader";

  @import "../pages/Linq/readers/readerSpecifications/readerSpecifications";

  @import "../pages/Linq/upgradeFirmware/upgradeFirmware";

  @import "../pages/Linq/readersConfigTemplates/readersConfig.scss";

  @import "../pages/Linq/readers/gateWayPanel/gateWayDeviceInfo/general/general";

  @import "../pages/Linq/readersApplyTemplate/applyDCID";

  @import "../pages/Linq/readers/resetReader/resetReader";

  @import "../pages/login/termsOfService/termsOfService";

  @import "../pages/Linq/readers/onPremDownload/onPremDownload";

  @import "../pages/Linq/createDcid/createDcid";

  @import "../pages/Linq/createDcid/components/TemplateEditor/TemplateEditor";

  @import "../pages/Linq/createDcid/components/DcidCopyTemplate/DcidCopyTemplate.scss";
}

html,
body,
#root {
  min-height: 100vh;
}
.hid-modal {
  z-index: 9998 !important;
}
/*Layout ===========*/
%text-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hid-fixed-container__wrapper {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.hid-fixed-container__main {
  background-color: #f2f2f2 !important;
}

.hid-inline-flex {
  display: inline-flex;
}

.hid-flex-jc-space-between {
  justify-content: space-between;
}

.hid-flex-jc-space-end {
  justify-content: flex-end;
}

.hid-flex-ai-start {
  align-items: flex-start;
}

.hid-white-space {
  white-space: nowrap;
}

.hid-button.hid-origo__white-space {
  margin-left: 2px;
}

.hid-galaxy {
  .hid-typography__h1__override {
    font-size: 2rem;
  }

  .hid-typography__normal {
    font-weight: normal;
  }

  .hid-empty-button {
    padding: 4px;
    cursor: pointer;
  }

  .hid-pagination-container {
    background-color: var(--color-white);
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .hid-address-font {
    font-weight: 400;
  }

  .hid-origo-text {
    color: #333333;
    font-weight: 400;
  }
}

.hid-button.hid-origo__button {
  width: 350px;
  margin-top: 52px;
  line-height: 20px;
}
.pointer {
  cursor: pointer;
}

.hid-font-500 {
  font-weight: 500;
}

.hid-font-600 {
  font-weight: 600;
}

.hid-font-dovegrey {
  color: var(--color_dove_grey);
}

.hid-no-toggle-margin {
  .hid-form-element {
    margin-bottom: 0;
  }
}

.hid-full-height {
  height: 100%;
}

.hid-flex-ai-start {
  align-items: flex-start;
}

.hid-flex-ai-start {
  align-items: flex-start;
}
.hid-flex-align-center {
  align-items: center !important;
}
.hid-text-left {
  text-align: left;
}
.hid-text-right {
  text-align: right;
}

h1 {
  font-family: var(--font-family-accent);
  font-size: var(--type-size-2xl);
  letter-spacing: -0.4px;
}
.h-10 {
  min-height: 10em;
}
.hid-linq {
  &__white-card {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 2px;
  }
}

.hid-galaxy {
  .buorg {
    position: absolute;
    position: fixed;
    z-index: 111111;
    width: 98%;
    top: 10px;
    left: 10px;
    right: 10px;
    border-radius: 5px;
    text-align: center;
    color: #000;
    background-color: #fff;
    border-bottom: none;
    font: 14px Roboto, Helvetica, sans-serif;
    box-shadow: 2px 2px 4px 0px #d3d3d3;
    line-height: 19px;
    font-weight: 400;
    animation: 1s ease-out 0s buorgfly;
  }

  .buorg-pad {
    padding: 9px;
    line-height: 1.7em;
  }

  .buorg-buttons {
    display: block;
    text-align: center;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: normal;
    background: rgba(0, 46, 86, 1);
    white-space: nowrap;
    margin: 0 15px;
    display: inline-block;
  }

  #buorgig {
    background-color: #fff;
    color: rgba(0, 46, 86, 1);
    box-shadow: none;
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 5px 12px 5px 9px;
      line-height: 1.3em;
    }
  }

  @keyframes buorgfly {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
  }

  .buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    background: no-repeat 0px center;
    border: 1px solid red;
    display: none;
  }

  .buorg-main-div {
    display: grid;
    flex: 1 1;
    grid-template-columns: 100px 1fr 300px;
    padding: 5px;
  }

  .buorg-bold-text {
    font-weight: 400;
    font-size: 20px;
  }

  .buorg-browser-img {
    width: 53px;
    height: 53px;
  }

  .burog-main-text {
    text-align: left;
    padding: 5px;
  }

  .buorg-btn-div {
    display: flex;
    justify-content: right;
    padding: 15px;
  }
 
}
