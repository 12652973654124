.hid-authorizedkey-container{
    border: var(--spacing-01) solid var(--light_gray);
    padding: 2%;
    margin-left: -1%;
}

.hid-authorizedKey-cardContainer{
    display: flex;
    justify-content: flex-end;
    gap:10px;

    .sortButton{
        color:black;
    }

    @media (min-width:540px){
        #search{
            width:300px;
        }
    }
}

.hid-authorizedKeys-gridContainer{
    display:flex;
    flex-wrap:wrap;
    gap:30px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 45vh;
    padding : 1% 0 1% 1%;
   

    .cardContainer{
        text-align: center;
    }

    .adminCardContainer{
        width:145px;
        text-align: center;

    }
}

.hid-authorizedKeys-listContainer{
    display:flex;
    flex-wrap:wrap;
    gap:40px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 45vh;
    padding : 1% 0 1% 1%;
    
    .listContainer{
        text-align: center;
        display: flex;
        flex-direction: row;

        .rightPane{
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 4px;
        }

        .expiryDate{
            width:154px;
        }
    }
}
.authorizedKeyCard{
    padding: 19px;
    width:240px;
    .hid-card__body{
        padding: 0;
    }
}