.hid-origo {
    &__linq {
        .hid-tabs__container {
            justify-content: center;
        }

        &--gateway-device-icon {
            color: var(--color-dove-grey);
            ;
        }
    }
}