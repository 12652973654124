.hid-linq {
  &__device-config-empty-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 387px;
  }
  &__card-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__card-container {
    width: 320px;
    height: 370px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    margin: 4px;
    flex-direction: column;
    position: relative;
  }
  &__top-media {
    height: 38.38%;
    border-radius: 8px 8px 0 0;
    background-color: var(--color_endeavor);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__bottom-body {
    height: 61.62%;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    display: flex;
    
    align-items: center;
    flex-direction: column;
  }
  &__card-icon {
    position: absolute;
    top: 62px;
    color: #ffffff52;
  }
  &__card-icon-file {
    position: absolute;
    top: 77px;
    left: 108px;
    color: #ffffff;
  }
  
}
