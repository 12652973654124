.hid-origo {
  &__linq {
    &__banner {
      background: linear-gradient(198.45deg, #006bc8 0%, #004c9f 70.91%);
      padding: 36px;
      border-radius: 4px;
      color: white;
    }
  }

}
button.text-left{
  text-align: left;
    }
    .align-firstChild-right:first-child{
      text-align: end;
    }