.hid-linq__btn-bg {
    .hid-button--flat {
      color: var(--color_light_smoke);
  
      .hid-button__icon__with-label {
        svg {
          path {
            fill: var(--color_light_smoke);
          }
        }
      }
    }
  }
  .hid-linq {
    &__search-input {
      .hid-form-element__search-icon {
        svg {
          path {
            fill: var(--color_endeavor);
          }
        }
      }
    }
    &__copy-bg {
      background-color: #fff;
      width: 100%;
    }
    &__search-text{
      .hid-typography__body--short-01 {
          color: gray;
      }
    }
    &__border-radius-04 {
      border-radius: 4px;
    }
    &__border-right-02 {
      border-right: 2px solid var(--color_light_smoke);
    }
    &__card {
      .hid-card {
        background-color: var(--color_light_smoke);
      }
      .hid-card__body {
        padding: 10px;
      }
      .hid-card__footer {
        padding: 0;
      }
    }
    &__icon-link {
      background-color: var(--color_progress);
    }
  }