.update-firmware {
  &__title {
    margin-left: auto;
    margin-right: auto;
  }
}
.w-90 {
  width: 90%;
  margin-top: 2%;
}
.hid-inline-status--positive {
  .hid-inline-status__label {
    color: var(--color_active);
  }
}
.hid-inline-status--danger {
  .hid-inline-status__label {
    color: var(--color_failed);
  }
}
.progress-notification {
  .hid-notification__close-btn {
    display: none;
  }
}
.text-primary {
  color: var(--color_endeavor);
  font-weight: 500;
}
.reader-name {
  font-size: 14px;
  font-weight: 500;
}
.phase {
  border: 4px solid #ccc;
  border-radius: 5px;
  flex: 1;
  margin: 2px;
}
.success-phase {
  border-color: #00aeef;
}
.failed-phase {
  border-color: var(--color_failed);
}
.icon-enabled {
  color: black;
  cursor: pointer;
}
.icon-disabled {
  color: grey;
  cursor: default;
}
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #007bff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 50%; /* Adjust width as needed for progress */
}
.reader-card {
  position: relative;
  .color-default {
    color: #00aeef;
  }
  .updating-content {
    align-items: baseline;
  }
}
.reader-list {
 .hid-card{
  .hid-card__body {
    padding: 0;
    // padding-top: 2px;
  }
 }
  
}
.red-text{
  color: #D31245;
  font-size: 16px;
  font-weight: 700;
}
