.hid-origo {
  &__dropdown {
    .hid-dropdown__control {
      background-color: #002e56;
    }
    .hid-dropdown__placeholder {
      color: #ffff;
    }
    .hid-dropdown__custom-icon svg {
      color: #ffff !important;
    }
    .css-qc6sy-singleValue {
      color: #ffff;
    }
    .css-1dimb5e-singleValue {
      color: #ffff;
    }
    .hid-dropdown__control .hid-dropdown__custom-icon path {
      fill: #fff;
    }
  }
  &__comment-field {
    .hid-form-element__control {
      padding: 12px;
    }
  }
  &__required {
    color: var(--color_failed);
  }
  &__config-p-color {
    color: rgba(34, 34, 34, 0.67);
  }
}
