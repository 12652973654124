.reader-select {
  .w-20 {
    width: 15%;
  }
}
.w-30 {
  width: 31%;
}
.mb-15{
  margin-bottom: 15%;
}
.warning-box{
  border: 1px solid #00AEEF;
  padding: 10px;
  border-left-width: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.danger-box{
  border: 1px solid #D31245;
  padding: 10px;
  border-left-width: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.success-box{
  border: 1px solid #94BB20;
  padding: 10px;
  border-left-width: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.reader-select-modal {
  .hid-modal__container--large {
    max-width: 80% !important;
    margin-top: 6% !important;
  }
}
div.reader-select-modal{
  z-index: 1 !important
}
