.default-color {
  color: #00aeef;
}
.filure-color {
  color: var(--color_failed);
}
.success-color {
  color: var(--color_active);
}
.disabled-color {
  color: var(--color_draft);
}
.rotate-90-deg {
  transform: rotate(90deg);
}
.color-black {
    color: black;
}
.disabled-colour {
    color: lightgray;
}
div.hid-grid.ml-20{
  margin-left: 10%;
}
.header-text{
  font-size: 26px;
  font-weight: 500;
}
