.hid-origo {
  &__container {
    display: flex;
    position: relative;
  }
  &__tabs {
    padding: 24px;
    border: none;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    min-width: 50px;
    max-width: 83px;
    box-sizing: content-box;
    position: relative;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid var(--light_gray);
    }
  }
  &__active-tabs {
    background-color: rgba(176, 203, 234, 0.24);
    color: var(--color_tab_selected);
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 5px;
      background-color: var(--color_tab_selected);
    }
  }
}
