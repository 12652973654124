.default-color {
  color: #002e56;
  cursor: pointer;
}
.disabled-color {
  color: grey;
}
.arrow-container {
  border-left: 1px solid #dddddd;
  display: flex;
  justify-content: center;
}
.m-15 {
  margin-right: 15%;
}
.progress-notification {
  .hid-galaxy .hid-notification__icon {
    margin-left: 8px;
  }
  .hid-notification__icon {
    padding-right: 10px;
  }
}

.hid-notification__body {
  width: 104%;
}
.partialSuccess {
  .hid-progress-bar__indicator {
    background-color: #f8971d;
  }
}
.success {
  .hid-progress-bar__indicator {
    background-color: #94bb20;
  }
}
.failure {
  .hid-progress-bar__indicator {
    background-color: red;
  }
}

.progress-notification.hid-notification__wrapper.topRight {
  z-index: 999;
  top: 40px;
}
.upgrade-firmware-content{
  border: 1px solid lightgray;
  padding: 1%;
  margin: 2% -3% 5% -7%;
  .clear-all{
    text-align: end;
  }
}
.progress-container{
  padding: 2% ;
}
.blue-text{
  color: #00539B;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
