.warningSymbol{
    color:#B5ADA5
}

.container{
    height:80vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.tryAgainBtn{
    border: 1px solid #0057B7 !important;
}