.hid-origo {
  &__terms-scrollable-box {
    max-height: 350px;
    border: 2px solid var(--color_endeavor);
    border-radius: 4px;
    padding: 24px;
    background-color: #fafafa;
    overflow-y: auto;
  }
  &__text-layout {
    padding: 0px 90px 0px 90px;
  }
  &__global-tm {
    font-weight: 600;
  }
}

.hid-linq__underline {
  text-decoration: underline;
  font-weight: 600;
}

.hid-spacing__spacing {
  p,
  li {
    margin-top: 16px;
  }
}
.hid-linq__heading {
  font-size: 26px;
  font-weight: 600;
}
.hid-linq__contract-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #676aff;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
  }
}
.hid-linq__modal {
  height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}
