.hid-origo {
  &__background {
    background: linear-gradient(198.45deg, #006bc8 0%, #004c9f 70.91%);
  }
  &__foreground {
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
    padding: 48px 0;
    @media (max-width: 980px) {
      display: flex;
      justify-content: center;
      padding: 48px 0;
    }
  }
}
