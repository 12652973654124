.hid-origo {
  &__linq {
    &__reset-label-lg {
      font-size: var(--type-size-base);
    }
    &__reset-label-sm {
      font-size: var(--type-size-xs);
    }
    &__reader-config-found-div {
      border: 1px solid var(--color_blue_div);
      border-left-width: 3px;
      padding: 10px;
    }
    &__reset-img-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
    &__cursor-pointer{
      cursor: pointer;
    }
    &--reset-error-message{
      border: 1px solid #d31245;
      border-left-width: 4px;
      padding: 2%;
    }
  }  
}
