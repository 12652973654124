.hid-downloadGateway {
  background: linear-gradient(194.7deg, #006bc8 42.77%, #004c9f 122.43%);
  border-radius: var(--spacing-01);
  margin-bottom: var(--spacing-06);
  display: flex;
  justify-content: center;
  position: relative;

  &__close {
    color: white;
    position: absolute;
    right: var(--spacing-03);
    top: var(--spacing-03);
    cursor: pointer;
  }

  .download-success {
    text-align: center;
    background-color: white;
    width: 30%;

    &__title {
      font-size: 38px;
      font-weight: 400;
      color: #494949;
    }

    &__message {
      font-size: 18px;
      font-weight: 400;

      &-download {
        color: var(--color-endeavor);
        font-weight: 500;
      }
    }
  }

  &__main-container {
    width: 100%;
    padding: 15px;
  }
  &__white-inner-container {
    width: 100%;
    background-color: white;
  }
  &__inner-container {
    padding: 24px;
    border: 1px solid #DDDDDD;
  }
  &__device-os-container {
    padding: 40px;
    width: 100%;
  }  
  &__p {
    margin-bottom: 22px !important;
    margin-top: 10px !important;
  }
}

.label {
  margin-bottom: var(--spacing-06);
}

.operating-system {
  position: relative;
  color: var(--color-endeavor);
}

.operating-system::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: var(--spacing-01) solid var(--color-endeavor);
  box-decoration-break: clone;
}

.no-underline {
  text-decoration: none;
}