.hid-origo {
  &__mt-2 {
    margin-top: 2px;
  }

  &__mt-50 {
    margin-top: 50px;
  }

  &__mt-10 {
    margin-top: 10px;
  }
  &__mt-15 {
    margin-top: 15px;
  }

  &__mt-35 {
    margin-top: 35px;
  }

  &__mb-50 {
    margin-bottom: 50px;
  }

  &__mb-95 {
    margin-bottom: 95px;
  }

  &__mr-10 {
    margin-right: 12px;
  }

  &__pl-10-pr-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__select-readers-div {
    margin-top: 18px;
    background-color: var(--color_light_smoke);
    border: 1px solid var(--light_gray);
    padding: 15px;
  }

  &__grey-small-text {
    color: var(--color_draft);
    font-size: 14px;
  }

  &__small-text {
    font-size: 14px;
  }

  &__bold-text {
    font-weight: 700;
  }

  &__pl-10 {
    padding-left: 10px;
  }

  &__fs-40 {
    font-size: 40px;
  }

  &__fs-32 {
    font-size: 32px;
  }
  &__fs-30 {
    font-size: 30px;
  }
    &__fs-32 {
    font-size: 1.9vw;
    font-weight: 500;
  }

  &__padding-16 {
    padding: 16px;
  }

  &__select-reader-center-text {
    justify-content: space-around;
    display: flex;
  }

  &__flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__reader-config-found-div {
    border: 1px solid var(--color_blue_div);
    border-left-width: 3px;
    padding: 10px;
  }

  &__custom-grey-button {
    border-radius: 20px;
    background: white;
    color: black;
    border: 1px solid var(--color_light_grey);
  }

  &__custom-grey-button:hover {
    border-radius: 20px;
    background: white;
    color: black;
    border: 1px solid var(--color_light_grey);
  }

  &__custom-light-green-button {
    border-radius: 20px;
    background: var(--color_light_green);
    color: black;
    border: 1px solid var(--color_active);
  }

  &__custom-light-green-button:hover {
    border-radius: 20px;
    background: var(--color_light_green);
    color: black;
    border: 1px solid var(--color_active);
  }

  &__grey-card {
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  }

  &__right-card {
    padding: 17px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  }

  &__choose-dcid-checkbox-div {
    display: flex;
    justify-content: end;
    padding-top: 10px;
  }

  &__display-flex {
    display: flex;
  }

  &__flex-spacebetween {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__flex-right {
    display: flex;
    justify-content: right;
  }

  &__selected-dcid-div {
    margin-top: 18px;
    border: 1px solid var(--light_gray);
    padding: 15px;
  }

  &__blue-card {
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #51aeff;
    background: rgba(81, 174, 255, 0.08);
  }

  &__padding-0 {
    padding: 0px;
  }

  &__text-transform-none {
    text-transform: none;
  }

  &__apply-config-tabs {
    .hid-tabs__item-group {
      border-radius: 10px;
      background: #f6f6f6;
    }
    .hid-tabs .hid-tabs__item--selected {
      background: #002e56;
      color: #fff;
    }
  }
}
.create-conf {
  .hid-dropdown--is-disabled {
    .hid-dropdown__control {
      background-color: var(--color-nobel);
    }
  }
}
.hid-linq__white-space__nowrap {
  white-space: nowrap;
}

.hid-linq__icon{
  &-circleCheck {
     path {
    fill: #00f000;
  }
}
&-circleXmark {
  path {
    fill: #ff0000;
  }
}
}