.hid-origo {
  &__reader-layout {
    background-color: var(--color-white);
    .default-color {
      color: var(--color-endeavor);
    }
    .filure-color {
      color: var(--color_failed);
    }
    .success-color {
      color: var(--color_active);
    }
    .disabled-color {
      color: var(--color_draft);
    }
  }
  &__green-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #94bb20;
  }
  &__yellow-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f8971d;
  }
  &__blue-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #00aeef;
  }
  &__red-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--color_failed);
  }
  &__delete-bar-text {
    color: var(--color_failed);
  }
  &__readers-divider {
    padding: 0px 84px 0 84px;
  }
  &__table-layout {
    background-color: #d0e6ff;
  }
  .no-underline a {
    text-decoration: none;
  }
}
.red-color {
  color: red;
}
.error-box {
  border: 1px solid #d31245;
  padding: 10px;
  border-left-width: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.discovered-text {
  background-color: var(--color_active);
  color: white;
  padding: 5px;
}
