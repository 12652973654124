.status-box {
  display: flex;
  padding: 5%;
}
.status-error {
  border: 2px solid #d31245;
  border-left-width: 4px;

  svg {
    color: #d31245;
  }
}
.status-blank {
  box-shadow: 0px 1px 4px 0px #0000004d;
  padding: 0;
  margin-top: 5%;
}
